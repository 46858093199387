// Import react
import React, { useEffect, useState } from 'react';

// Import React reveal animations
import { Zoom } from "react-awesome-reveal";

// Import MUI components
import Tooltip from '@mui/material/Tooltip';

// News component
const News = () => {
  const [posts, setPosts] = useState([]);

  // Number of visible elements
  const [visibleItems, setVisibleItems] = useState(3); 

  // Call Instagram API
  useEffect(() => {
    const host = "https://graph.instagram.com/me/media?";
    const path = "fields=media_url,permalink&access_token=IGQWRNWTQtTE1wNUhTQk5FWVJNMGIwWGdkOVNmVGVuQlNuVFhlNDZATMlFfTHZApcm1IdXo1Y3h3MjZAPR0h6TExXaHZAKUkpJaTFJRHlwaWhQTGRJSE5QdVlKMENZAdmp3Uzg0eVlvWDRkX2lWalRBTEY3WkFpWHVNd2MZD";
    
    // Handle Instagram feed DATA
    fetch(host + path)
      .then((response) => response.json())
      .then((dataArray) => {
        setPosts(dataArray.data);
      });    
  }, []);

  // Handle images preview
  const handleLoadMore = () => {
    setVisibleItems(prev => prev + 3);
  };

  return (
    <>
      <div id='news-section' className='news-section'>
        <h2>Novedades</h2>
        <span className='bg-sky-700'></span>

        <div className='news-instagram-feed'>
          {posts.slice(0, visibleItems).map((post, index) => (
            <div className='instagram-feed-posts' key={index}>
              <Zoom duration={2000} triggerOnce={true}>
                <a href={post.permalink}>
                  <Tooltip title="Click para ver la publicacion"  placement="top-end">
                    <img className='instagram-feed-images' src={post.media_url} alt="Instagram Post"/>
                  </Tooltip>
                </a>
              </Zoom>
            </div>
          ))}
        </div>
      </div>

      <div className='more-button-section'>
        <button onClick={handleLoadMore} class="bg-sky-700 group relative inline-flex h-12 items-center justify-center overflow-hidden rounded-md bg-neutral-950 px-6 font-medium text-neutral-200 duration-500">
            <div class="translate-x-0 opacity-100 transition group-hover:-translate-x-[150%] group-hover:opacity-0 uppercase">
                Ver mas
            </div>
            <div class="absolute translate-x-[150%] opacity-0 transition group-hover:translate-x-0 group-hover:opacity-100">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6">
                    <path d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                </svg>
            </div>
        </button>
      </div>
    </>
  );
};

// Export News component
export default News;